.home-banner {
  max-height: 450px;

  .login-form-container {
    width: 400px;
    // background: linear-gradient(171.91deg, #0074c5 5.43%, #0137a1 93.08%);
    background-image: url("../../assets/images/blue-background.svg");
    background-size: cover;
    background-position: center right;
  }

  .banner-bg-image {
    background-image: url("../../assets/images/samsung-banner.jpg");
    background-position: center right;
    background-size: cover;
    height: 450px;
  }
}
