@font-face {
  font-display: swap;
  font-family: "SamsungOne";
  font-style: normal;
  font-weight: normal;
  src: local("SamsungOne"), url("./assets/fonts/SamsungOne-400.woff2") format("woff2"), url("./assets/fonts/SamsungOne-400.woff") format("woff"),
    url("./assets/fonts/SamsungOne-400.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-display: swap;
  font-family: "SamsungOne";
  font-style: normal;
  font-weight: bold;
  src: local("SamsungOne"), url("./assets/fonts/SamsungOne-700.woff2") format("woff2"), url("./assets/fonts/SamsungOne-700.woff") format("woff"),
    url("./assets/fonts/SamsungOne-700.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-display: swap;
  font-family: "SamsungSharpSans";
  font-style: normal;
  font-weight: bold;
  src: local("SamsungSharpSans"), url("./assets/fonts/SamsungSharpSansBd.woff2") format("woff2"), url("./assets/fonts/SamsungSharpSansBd.woff") format("woff"),
    url("./assets/fonts/SamsungSharpSansBd.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-display: swap;
  font-family: "SamsungSharpSans";
  font-style: normal;
  font-weight: normal;
  src: local("SamsungSharpSans"), url("./assets/fonts/samsungsharpsans.woff") format("woff");
}

.SamsungOneNormal {
  font-family: "SamsungOne";
  font-style: normal;
  font-weight: normal;
}

.SamsungOneBold {
  font-family: "SamsungOne";
  font-style: normal;
  font-weight: bold;
}

.SamsungSharpSansBold {
  font-family: "SamsungSharpSans";
  font-style: normal;
  font-weight: bold;
}

.SamsungSharpSansNormal {
  font-family: "SamsungSharpSans";
  font-style: normal;
  font-weight: normal;
}
