@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import "./styles/samsung.scss";

@tailwind components;
@tailwind utilities;

$fontSize: 12pt;
$fontFamily: "SamsungOne", arial, sans-serif;
$fontWeight: normal;

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: $fontFamily;
  font-weight: $fontWeight;
}

#samsung-portal {
  width: 100%;
  height: 100%;

  input[type="text"],
  textarea {
    font-family: $fontFamily;
  }

  .tv-and-you-header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }

  .faq-title {
    // font-style: normal;
    // font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
}

.popup-overlay {
  background: #000000a1;
}

.modal {
  border: 1px solid black;
  max-width: 50%;
  min-width: 400px;
  margin: auto;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
}
