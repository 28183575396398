.message-form {
  .custom-bg-blue {
    background: #00b3e3;
  }
  .comment-button {
    width: 65px;
    height: 65px;
    line-height: 95px;
    text-align: center;
    border-radius: 50%;
  }

  .helper-message:before {
    content: "";
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 15px solid #00b3e3;
    left: 0;
    margin-left: -13px;
    top: 50%;
    margin-top: -2px;
  }
}
